.subscription {
  max-width: 400px;
  margin: 50px auto;
  text-align: center;
}

.subscription-title {
  font-weight: 600;
  font-size: 22px;
}

.subscription-description {
  margin-top: 10px;
  font-size: 16px;
  color: #888;
}

.subscription-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.subscription-button {
  padding: 5px 20px;
  border-radius: 6px;
  cursor: pointer;
  &.unsubscribe {
    background-color: red;
    color: #fff;
  }
  &.cancel {
    background-color: #ddd;
  }
  &:hover {
    opacity: 0.8;
  }
}
