.opl-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #000000;
  z-index: 99;
  
  &:not(.opl-wrapper-single):not(.opl-wrapper-inline):not(.opl-minimized) {
    background-color: #00000042;
  }

  &.opl-wrapper-image-top {
    &:not(
        .opl-wrapper-inline,
        .opl-wrapper-bottom,
        .opl-wrapper-top,
        .opl-wrapper-center
      ) {
      .opl-box {
        &.opl-box-large {
          .opl-title {
            max-width: 300px;
            margin: auto;
          }
        }

        &.opl-box-medium {
          .opl-title {
            max-width: 230px;
            margin: auto;
          }
        }

        &.opl-box-small {
          .opl-title {
            max-width: 185px;
            margin: auto;
          }
        }
      }
    }
  }

  &.opl-minimized {
    .opl-box {
      // box-shadow: 0 0px 7px 0px rgb(0 0 0 / 20%);
      box-shadow: rgba(13, 13, 13, 0.4) 0px 0 6px -2px;
      max-width: 255px;
      flex-grow: 1;
      border-radius: 12px;
      .opl-item-content {
        min-width: auto;
        align-items: flex-start;
      }
    }

    .opl-ul {
      li {
        .opl-item-layout {
          margin: 0;
        }
        margin-bottom: 0;
        &:last-child {
          margin-bottom: 0;
        }
        .opl-item-get {
          margin-bottom: 0;
        }
      }
    }
  }

  &:not(.opl-wrapper-inline) {
    &:not(.opl-wrapper-image-top) {
      .opl-item-content {
        &.opl-content-small {
          max-width: 175px;
          min-width: 175px;
        }
        &.opl-content-medium {
          max-width: 175px;
          min-width: 175px;
        }
        &.opl-content-large {
          max-width: 175px;
          min-width: 175px;
        }
      }

      &:not(
          .opl-wrapper-top,
          .opl-wrapper-bottom,
          .opl-wrapper-inline,
          .opl-wrapper-center
        ) {
        .opl-box {
          &.opl-box-large {
            .opl-title {
              max-width: 515px;
            }
          }

          &.opl-box-medium {
            .opl-title {
              max-width: 415px;
            }
          }

          &.opl-box-small {
            .opl-title {
              max-width: 335px;
            }
          }
        }
      }
    }
  }

  &.opl-wrapper-image-left,
  &.opl-wrapper-image-right,
  &.opl-minimized,
  &.opl-inline-minimized {
    &:not(.opl-wrapper-inline) {
      .opl-list-item.opl-item-small,
      .opl-list-item.opl-item-medium,
      .opl-list-item.opl-item-large {
        max-width: none;
      }
      .opl-item-content.opl-content-large {
        min-width: 320px;
      }
      .opl-item-content.opl-content-medium {
        min-width: 220px;
      }
      .opl-item-content.opl-content-small {
        min-width: 150px;
      }
    }
    &.opl-vertical-order.opl-wrapper-inline {
      .opl-item-content.opl-content-large,
      .opl-item-content.opl-content-medium,
      .opl-item-content.opl-content-small {
        min-width: auto;
      }
      .opl-box {
        &.opl-box-center {
          .opl-item-content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    .opl-item-layout {
      display: flex;
    }
    .opl-item-image {
      margin-bottom: 0;
    }
    .opl-box {
      &.opl-box-right {
        .opl-ul {
          justify-content: initial;
        }
        .opl-list {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  &.opl-wrapper-image-left,
  &.opl-wrapper-image-right {
    .opl-ul {
      li {
        .opl-item-layout {
          padding: 10px 25px 10px 10px;
          .opl-item-title {
            svg {
              display: none;
            }
          }
          .action-save {
            display: none;
          }
        }

        .opl-item-actions {
          display: flex;
        }
      }
    }
  }

  &.opl-wrapper-image-left {
    .opl-item-image {
      margin-right: 10px;
    }
  }

  &.opl-wrapper-image-right {
    .opl-item-layout {
      flex-direction: row-reverse;
    }
    .opl-item-image {
      margin-left: 10px;
    }
  }

  &.opl-wrapper-left,
  &.opl-wrapper-right {
    .opl-box {
      position: relative;
      display: flex;
      flex-direction: column;
      max-height: 100%;
      height: 100%;
      overflow: auto;
      .opl-list-item {
        .opl-item-content {
          // min-width: 220px;
        }
      }
      .opl-title {
        span {
          // margin: auto;
        }
      }
    }
  }

  &.opl-wrapper-left {
    .opl-box {
      margin: 0 auto 0 0;
      height: 100%;
    }
    &.opl-minimized {
      .opl-box {
        height: auto;
      }
    }
  }

  &.opl-wrapper-right {
    .opl-box {
      margin: 0 0 0 auto;
      height: 100%;
    }

    &.opl-minimized {
      .opl-box {
        height: auto;
      }
    }
  }

  &.opl-wrapper-center {
    display: flex;
    justify-content: center;
    align-items: center;
    .opl-box {
      margin: auto;
      height: fit-content;
      width: fit-content;
      border-radius: 12px;
    }

    &:not(.opl-minimized) {
      .opl-ul {
        li {
          margin-right: 0;

          &:last-child {
            margin-right: 15px;
          }
        }
      }
    }
  }

  &.opl-minimized,
  &.opl-inline-minimized {
    overflow: hidden;
    &.opl-wrapper-inline {
      .opl-box {
        .opl-title {
          .opl-title-label {
            min-height: auto;
            max-height: none;
          }
        }
      }
    }
    .opl-box {
      // box-shadow: 0 0px 7px 0px rgb(0 0 0 / 20%);
      .opl-title {
        align-items: center;
        justify-content: flex-start;
        padding: 10px 15px 0;
        overflow: hidden;
        border-bottom: 0;

        .opl-title-label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          overflow: hidden;
          padding-right: 0;
          min-height: 55px;
          max-height: 55px;
        }
      }
      .opl-ul {
        flex-grow: 1;
        // background-color: hsl(0deg 11.73% 70.32% / 23%);
      }
    }

    &.opl-wrapper-inline {
      .opl-box {
        .opl-ul {
          flex-grow: 0;
          padding: 0;
          background-color: transparent;
        }
      }
      .opl-list-item {
        .opl-item-layout {
          margin: 0;
        }
      }
    }

    &.opl-wrapper-topLeft {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      .opl-box {
        margin: 0 auto auto 0;
      }
      .opl-ul {
        flex-direction: column;
      }
    }

    &.opl-wrapper-topRight {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      .opl-box {
        margin: 0 0 0 auto;
      }
      .opl-ul {
        flex-direction: column;
      }
    }

    &.opl-wrapper-bottomLeft {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      .opl-box {
        margin: auto auto 0 0;
      }
      .opl-ul {
        flex-direction: column;
      }
    }

    &.opl-wrapper-bottomRight {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      .opl-box {
        margin: auto 0 0 auto;
      }
      .opl-ul {
        flex-direction: column;
      }
    }

    &.opl-wrapper-top {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      .opl-box {
        margin: 0 auto;
      }
    }

    &.opl-wrapper-bottom,
    &.opl-wrapper-top,
    &.opl-wrapper-center {
      justify-content: center;
      ul {
        flex-direction: column;
      }
    }

    .action-get {
      padding: 4px 20px;
      line-height: 15px;
      min-height: 30px;
      margin-right: 0;
    }
    .action-save {
      flex-grow: 1;
      justify-content: flex-end;
      cursor: default;
      &:hover {
        opacity: 1;
      }
      svg {
        margin-right: 0;
        margin: 0 2px;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .opl-item-title {
      align-items: flex-start;
      margin-bottom: 0;
      min-height: 30px;
      .opl-item-title-text {
        flex-grow: 1;
        line-height: 15px;
      }
    }
    .opl-item-content {
      flex-grow: 1;
      text-align: left;
    }
    .opl-item-image {
      align-items: flex-start;
      img {
        width: 60px;
        height: 60px;
        min-width: 60px;
        min-height: 60px;
        border-radius: 6px;
        margin: 0;
      }
    }
    .opl-item-button,
    .opl-item-description {
      display: none;
    }
    .opl-item-get {
      min-height: 40px;
      align-items: center;
      .opl-switch-wrapper {
        min-height: 28.85px;
      }
    }
  }

  &.opl-vertical-order.opl-wrapper-inline {
    .opl-title {
      max-width: 500px;
    }
    .opl-list {
      .opl-ul {
        display: block;
        flex-grow: 1;
        .opl-list-item {
          max-width: 500px;
          margin-right: 15px;
        }
      }
    }
  }
}

.opl-wrapper-inline {
  position: relative;
  .opl-box {
    height: 100%;
    &.opl-box-right {
      .opl-title {
        padding-right: 15px;
        .close-circle {
          display: none;
        }
      }
    }
  }
  .opl-inner-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  .opl-list {
    display: flex;
    flex-grow: 1;
  }
  .opl-ul {
    height: 100%;
  }
  &:not(.opl-vertical-order) {
    .opl-item-content {
      min-width: 120px;
      max-width: 200px;
      flex-grow: 1;
    }
  }
  .opl-list-item {
    align-self: baseline;
    flex-basis: 0;
    flex-grow: 1;
    margin-right: 0;

    &:last-child {
      margin-right: 15px;
    }
  }
  .close-circle {
    display: none;
  }
}
.opl-wrapper {
  &.opl-wrapper-bottom {
    display: flex;
    ul {
      flex-direction: row;
    }
    .opl-box {
      position: relative;
      align-self: flex-end;
      // min-height: 375px;

      margin: auto 0 0 0;
      flex-grow: 1;
    }

    &:not(.opl-minimized) {
      .opl-ul {
        li {
          margin-right: 0;

          &:last-child {
            margin-right: 15px;
          }
        }
      }
    }
  }
  &.opl-wrapper-top {
    .opl-box {
      position: relative;
      // min-height: 375px;
    }
    &:not(.opl-minimized) {
      .opl-ul {
        li {
          margin-right: 0;

          &:last-child {
            margin-right: 15px;
          }
        }
      }
    }
    .opl-ul {
      li {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
.opl-wrapper-right,
.opl-wrapper-left {
  display: flex;
  align-items: center;
  width: 100%;
  .opl-ul {
    display: block;
    li {
      // margin-bottom: 30px;
      padding: 0;
    }
  }
}

.opl-title-label {
  span {
    flex-grow: 1;
  }
  svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
    vertical-align: top;
    margin: 0 10px 0 0;
  }
}

// Align items style
.opl-wrapper {
  .opl-box {
    max-width: 100%;
    background-color: white;
    margin: 0 auto auto auto;
    overflow: hidden;

    &.opl-box-left {
      .opl-item-layout {
        justify-content: flex-start;
      }
    }
    // Align center
    &.opl-box-center {
      text-align: center;
      .opl-list {
        display: flex;
        justify-content: center;
      }
      .opl-item-layout {
        height: 100%;
      }
      .opl-item-layout,
      .opl-item-get,
      .opl-list-info {
        justify-content: center;
      }
      .opl-item-content {
        flex-grow: 1;
        align-items: center;
        .opl-item-button {
          align-self: center;
        }
      }
      .opl-item-image {
        img {
          object-position: center;
        }
      }
      .opl-item-title {
        justify-content: center;
        text-align: center;
      }

      .opl-title {
        justify-content: center;
        text-align: center;

        .opl-title-label {
          justify-content: center;
        }

        span {
          flex-grow: 0;
        }
      }
    }
    // Align right
    &.opl-box-right {
      text-align: right;
      .opl-item-image {
        img {
          margin-right: 0;
        }
      }
      .opl-item-content {
        align-items: flex-end;
        .opl-item-button {
          align-self: flex-end;
        }
      }
      .opl-item-get,
      .opl-ul {
        justify-content: flex-end;
        .opl-list-item {
          .opl-item-title {
            justify-content: flex-end;
            .opl-item-title-text {
              text-align: right;
            }
          }
        }
      }
      .opl-list-info {
        justify-content: flex-end;
      }

      .opl-title {
        justify-content: center;
        text-align: right;

        .opl-title-label {
          justify-content: flex-end;
          .opl-item-text {
            text-align: right;
          }
        }

        span {
          flex-grow: 0;
        }
      }
    }
  }
}
.opl-inner-box {
  position: relative;
}

.opl-inner-box-gift {
  display: block;
  height: 24px;
  svg {
    margin: auto;
    width: 24px;
    height: 24px;
  }
}

.opl-list {
  position: relative;
  padding-bottom: 30px;
}

.opl-email-capture {
  &.opl-minimized {
    .opl-list {
      .opl-list-info {
        &.opl-list-info-small {
          font-size: 8px;
          line-height: 14px;
          margin-bottom: 5px;
        }
      }
    }
  }

  &.opl-wrapper-right,
  &.opl-wrapper-left {
    &.opl-wrapper-image-top {
      .opl-box-small,
      .opl-box-medium {
        .opl-list {
          position: relative;
          padding-bottom: 90px;
        }
      }
    }
  }
  .opl-list {
    position: relative;
    padding-bottom: 70px;
    .opl-list-info {
      position: absolute;
      bottom: 0;
      height: 70px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 15px 0 15px;
      font-size: 13px;
      line-height: 18px;

      &.opl-list-info-small {
        font-size: 11px;
        margin-bottom: 5px;
      }
      svg {
        min-width: 24px;
        min-height: 24px;
        margin-right: 10px;
      }
    }
    // padding: 0 15px;
  }
}

.icon-circle-info,
.save-icon {
  position: relative;
  display: block;
  min-width: 15px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
  &::before {
    content: "";
    position: absolute;
    right: -10px;
    top: calc(50% - 22px);
    width: 40px;
    height: 40px;
  }
}

.opl-title {
  padding: 15px;
  min-height: 52px;
  text-transform: uppercase;
  font-size: 18px;
  .close-circle,
  .minus-circle {
    position: absolute;
    right: 3px;
    top: 3px;
    padding: 7px;
    width: 26px;
    height: 26px;
    background-color: #e2e2e2;
    border-radius: 100%;
    z-index: 10;
    path,
    rect {
      fill: #a3a8b9;
    }
    cursor: pointer;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }

  .minus-circle {
    width: 20px;
    height: 20px;
    right: 3px;
    top: 3px;
    padding: 5px;
    border-radius: 100%;
  }
}

.opl-list-item {
  background-color: #ffffff;
  &:not(.opl-item-display) {
    label {
      border: none !important;
      cursor: default;
    }
  }
  &.opl-item-getbutton {
    &:hover {
      label {
        cursor: pointer;
      }
    }
  }
  .opl-item-layout {
    position: relative;
    display: block;
    height: 100%;
    padding: 10px;
    margin: 0;
    border-radius: 8px;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border: 1px solid #d6dae5;
      border-radius: 8px;
      pointer-events: none;
    }

    // &:hover {
    //   &::before {
    //     content: "";
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     width: 100%;
    //     height: 100%;
    //     background-color: #00000010;
    //     z-index: 0;
    //     pointer-events: none;
    //   }
    // }
  }
}

.opl-item-actions {
  display: none;
  position: absolute;
  padding: 10px 5px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  right: 0;
  top: 0;
  .icon-circle-info {
    margin-bottom: 15px;
  }
}
.opl-list-item {
  position: relative;

  // &.opl-item-small,
  // &.opl-item-medium {
  //   max-width: 250px;
  // }
  // &.opl-item-large {
  //   max-width: 310px;
  // }
}
.opl-ul {
  display: flex;
  padding: 0;
  margin: 0;
  overflow: auto;

  li {
    margin: 15px;
    padding: 0;
    list-style: none;
    border-radius: 8px;
  }
}
// .opl-wrapper {
//   &:not(.opl-wrapper-left, .opl-wrapper-right) {
//     .opl-ul {
//       padding: 5px;
//       li {
//         margin: 5px;
//       }
//     }
//   }
// }

.opl-item-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-top: 0;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    pointer-events: none;
    &.image-xsmall {
      width: 60px;
      height: 60px;
      min-width: 60px;
      min-height: 60px;
    }
    &.image-small {
      width: 100px;
      height: 100px;
      min-width: 100px;
      min-height: 100px;
    }
    &.image-medium {
      width: 180px;
      height: 180px;
      min-width: 180px;
      min-height: 180px;
    }
    &.image-large {
      width: 280px;
      height: 280px;
      min-width: 280px;
      min-height: 280px;
    }
  }
}
.opl-wrapper {
  &:not(.opl-vertical-order, .opl-wrapper-inline) {
    .opl-item-content {
      max-width: 280px;
      min-width: 150px;
      display: flex;
      flex-direction: column;

      &.opl-content-small {
        max-width: 145px;
        min-width: 145px;
      }
      &.opl-content-medium {
        max-width: 180px;
        min-width: 180px;
      }
      &.opl-content-large {
        max-width: 280px;
        min-width: 280px;
      }
    }
  }
}

.opl-item-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  .opl-item-title-text {
    font-size: 15px;
    font-weight: 600;
    padding-right: 5px;
  }
}

.opl-max-1lines {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;

  @supports (-webkit-line-clamp: 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.opl-max-2lines {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.opl-item-get {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  &.space-between {
    justify-content: space-between;
  }
}

.action-get {
  padding: 4px 20px;
  line-height: 15px;
  min-height: 30px;
  margin-right: 10px;
  font-size: 12px;
  text-align: center;
  a {
    text-decoration: none;
  }
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.action-save {
  display: flex;
  align-items: center;
  font-size: 14px;
  svg {
    min-width: 12px;
    margin-right: 5px;
  }
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.opl-item-description {
  min-height: 40px;
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
}

.opl-item-button {
  margin-right: 15px;
  margin-bottom: 3px;
  align-self: baseline;
  border-bottom: 1px solid;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
}

.opl-switch-wrapper {
  display: flex;
  justify-content: flex-end;
  label {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    cursor: pointer;
    .opl-switch-toggle {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 18px;
      margin-right: 8px;
      border-radius: 30px;
      transition: right 0.2s linear;
      background-color: #f1f2f3;
      .opl-switch-toggle-before {
        position: absolute;
        top: 3px;
        right: calc(100% - 13px - 3px);
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background-color: #d7d7d7;
        transition: right 0.2s ease-in-out;
      }
    }
    .opl-selected {
      font-size: 12px;
      font-family: Poppins-Medium;
      .button-select {
        display: inline-block;
        background-color: #f2f2f2;
        padding: 2px 20px;
        border-radius: 6px;
      }
      .button-selected {
        display: none;
        background-color: #3ab78f;
        color: #fff;
        padding: 2px 20px;
        border-radius: 6px;
      }
    }
    .opl-switch-text {
      .unchecked-text {
        color: #656060;
        display: inline-block;
      }
      .checked-text {
        display: none;
      }
    }
  }
}

.opl-input {
  opacity: 0;
  height: 0;
  width: 0;
  z-index: -1;
  overflow: hidden;
  display: none;
  &:not(:checked) + .opl-item-layout {
    // border-color: transparent !important;
  }
}

.opl-input:checked + .opl-item-layout {
  position: relative;
  border-color: transparent;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: 2.2px solid #3ab78f;
    border-radius: 8px;
  }
  & .opl-switch-toggle {
    background-color: #3ab78f;
    opacity: 1;
    .opl-switch-toggle-before {
      right: 3px;
      left: auto;
      background-color: #ffffff;
    }
  }
  & .opl-switch-text {
    font-family: inherit;
    font-weight: 400;
    color: var(--success-color);
    .checked-text {
      display: inline-block;
    }
    .unchecked-text {
      display: none;
    }
  }
  & .opl-selected {
    .button-select {
      display: none;
    }
    .button-selected {
      display: inline-block;
    }
  }
}

.opl-close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  background-color: #f2f2f2;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  path {
    fill: #a3a8b9;
  }
  cursor: pointer;
  &:hover {
    background-color: #ddd;
  }
}

.icon-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 768px) {
  .opl-wrapper {
    &.opl-wrapper-left,
    &.opl-wrapper-right {
      .opl-box {
        height: 100%;
      }
    }
    &.opl-wrapper-image-top:not(
        .opl-wrapper-inline,
        .opl-wrapper-bottom,
        .opl-wrapper-top,
        .opl-wrapper-center
      ) {
      .opl-box {
        .opl-title {
          max-width: initial;
        }
      }
    }

    &.opl-wrapper-image-left:not(.opl-wrapper-inline) {
      .opl-item-layout {
        div.opl-item-content {
          max-width: initial;
          min-width: auto;
        }
      }
    }
  }
}
