.spin {
  display: inline-block;
  margin: auto;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}
@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.default-input {
  all: unset;
  font-size: 12px;
  padding: 5px 10px;
  border: 1px solid;
  box-sizing: border-box;
  &::placeholder {
    color: inherit;
    opacity: 0.4;
  }
}

.opl-extra-list {
  ul {
    padding: 10px;
    margin: 0;
    list-style: none;
    background-color: hsl(0deg 11.73% 70.32% / 23%);
    li {
      margin: 10px;
      padding: 10px;
      display: flex;
    }
  }
}
.opl-extra-list-content {
  flex-grow: 1;
}

.opl-extra-list-content-title {
  font-size: 13px;
  margin-bottom: 10px;
}

.opl-extra-list-content-footer {
  font-size: 10px;
  opacity: 0.7;
}

.opl-extra-list-image {
  margin-right: 10px;
  img {
    margin: auto;
    width: 70px;
    height: 70px;
    object-fit: cover;
  }
}

.opl-extra-list-content-email {
  display: flex;
}

.opl-extra-list-content-getbutton {
  flex-grow: 1;
  text-align: right;
  .action-get {
    display: inline-block;
  }
}

.opl-extra-list-content-inputs {
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 20px;

  .action-get {
    padding: 6px 20px 5px 20px;
    margin-right: 0;
    margin-left: 10px;
    text-transform: none;
    font-size: 12px;
    line-height: 14px;
  }

  .icon-spin {
    position: relative;
    margin: 5px 10px 0 10px;
    top: 0;
    left: 0;
    transform: none;
  }
}

.opl-extra-info {
  .opl-info-label {
    position: absolute;
    span {
      font-size: 11px;
    }
  }
}
.action-get-disabled {
  position: relative;
  pointer-events: none;
  opacity: 0.7;
}

.loader-absolute-li {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255 255 255 / 80%);
  border-radius: 6px;
  z-index: 2;
  .icon-spin {
    color: #000;
  }
}

@media screen and (max-width: 768px) {
  .opl-extra-list-content-inputs {
    flex-direction: column;
  }
  .opl-extra-list-content-email {
    display: block;
    input {
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .action-get {
      display: inline-block;
      margin: 0;
      margin-bottom: 10px;
    }
  }
  .opl-extra-list-content-getbutton {
    text-align: left;
    .action-get {
      margin: 0;
    }
  }
}
