@font-face {
    font-family: 'Newlook';
    src: url('./fonts/gibson-light.woff2.txt') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Newlook';
    src: url('./fonts/gibson-regular.woff2.txt') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Newlook';
    src: url('./fonts/gibson-semibold.woff2.txt') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}