.panel-header {
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  svg {
    transform: rotate(180deg);
    &.rotate {
      transform: rotate(0deg);
    }
  }
}

.dashed-button {
  border: 1px dashed;
  border-color: #656060;
  color: #656060;
  border-radius: 6px;
  width: 100%;
  padding: 6px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 1px;
}

.single-box {
  display: flex;
  width: 50vw;
  min-width: 700px;
  max-width: 90vw;
  min-height: 450px;
  max-height: 90vh;
  overflow: auto;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 0 10px -4px #0000009e;
  z-index: 10;
}

.single-action-get {
  padding: 6px 12px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.single-side-actions {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
  padding: 20px 0;
  border-radius: 8px;
  .action-save {
    margin-left: 10px;
  }
  .button {
    max-width: 150px;
  }
}

.offer-side {
  padding: 20px;
}

.side-actions {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  .action-save {
    margin-left: 10px;
  }
  .button {
    max-width: 150px;
  }
}

.offer-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 20px;
}

.content-extra {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  padding: 20px 15px 15px 0;
  font-size: 12px;
  a {
    text-decoration: none;
    &:hover {
      opacity: 0.8;
    }
  }
}

.content-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px 15px 0;
  font-size: 18px;
  font-weight: 600;
}

.single-close {
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 100%;
  z-index: 10;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  color: #292426;
  cursor: pointer;
  svg {
    width: 15px;
    height: 15px;
  }
  &:hover {
    opacity: 0.7;
  }
}

.button {
  padding: 4px 20px;
  font-size: 12px;
  margin-right: 10px;
  cursor: pointer;
  line-height: 15px;
  min-height: 30px;
  font-family: inherit;
  a {
    text-decoration: none;
  }
  &:hover {
    opacity: 0.7;
  }
}

.action-save {
  display: flex;
  align-items: center;
  min-width: 90px;
  font-size: 14px;
  svg {
    min-width: 12px;
    margin-right: 5px;
  }
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.content-panel {
  position: relative;
  margin-bottom: 15px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #d6dae5;
  border-radius: 12px;
}

.loading-overlay {
  position: relative;
  pointer-events: none;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.4);
  }
}

.panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-weight: 600;
    font-size: 15px;
  }
  svg {
    min-width: 12px;
  }
}

.panel-body {
  margin-top: 10px;
  font-size: 14px;
}

.side-image {
  img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
  }
}

.content-panel-email-switch {
  margin-bottom: 10px;
  .opl-switch-wrapper {
    justify-content: flex-start;
  }
}
.single-box {
  position: relative;
  .opl-switch-wrapper {
    padding: 0;
    text-align: left;
    flex-grow: 1;
    justify-content: center;
    label {
      .opl-switch-text {
        font-size: 15px;
        .unchecked-text,
        .checked-text {
          text-align: left;
          min-width: 115px;
        }
      }
      .opl-switch-toggle {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 18px;
        margin-right: 8px;
        border-radius: 30px;
        transition: right 0.2s linear;
        background-color: #f1f2f3;
        .opl-switch-toggle-before {
          position: absolute;
          top: 3px;
          right: calc(100% - 13px - 3px);
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background-color: #d7d7d7;
          transition: right 0.2s ease-in-out;
        }
      }
      .single-checked-text,
      .single-unchecked-text {
        display: inline-block;
        min-width: 80px;
        font-size: 14px;
      }
      .opl-input:checked {
        & ~ .opl-switch-toggle {
          font-family: inherit;
          font-weight: 400;
          color: var(--success-color);
          background-color: #4bd763;

          .opl-switch-toggle-before {
            right: 3px;
            background-color: #ffffff;
          }
        }
      }
    }
  }
}

.content-panel-input-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #292426;
}

.content-panel-input-description {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 400;
  color: #292426;
}

.content-panel-input {
  input {
    all: unset;
    width: 100%;
    font-size: 14px;
    padding: 10px 17px;
    border: 1px solid #d6dae5;
    background-color: #ffffff;
    color: #292426;
    border-radius: 8px;
    box-sizing: border-box;
    &::placeholder {
      color: inherit;
      opacity: 0.4;
    }
  }
}
.content-panel-submit {
  button {
    all: unset;
    display: block;
    margin: 20px auto 0;
    width: 100px;
    height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    background-color: #3ab78f;
    border-radius: 6px;
    cursor: pointer;
    svg {
      margin-left: 10px;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
    &:hover {
      opacity: 0.85;
    }
  }
}

.label-wrapper {
  position: relative;
  min-height: 18px;
  padding: 0 5px;
  margin-top: 5px;
}

.label-error {
  font-size: 11px;
  color: #f22727;
  vertical-align: top;
}

.label-success {
  font-size: 11px;
  font-weight: 500;
  color: inherit;
  vertical-align: top;
  color: #00b100;
  display: block;
}

.submit-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  input {
    position: relative;
    margin-right: 10px;
    visibility: hidden;
    display: none;
    & + .checkboxsvg {
      position: absolute;
      width: 18px;
      height: 18px;
      left: 0;
      top: 0;
      border-radius: 6px;
      background-image: url("../images/checkbox-uncheck.svg");
    }
    &:hover {
      & + .checkboxsvg {
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }
  input:checked {
    &:hover {
      & + .checkboxsvg {
        opacity: 0.9;
        cursor: pointer;
      }
    }
    & + .checkboxsvg {
      content: "";
      position: absolute;
      width: 18px;
      height: 18px;
      left: 0;
      background-image: url("../images/checkbox-checked.svg") !important;
    }
  }
}

.label-message {
  margin-left: 25px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #8d8888;
  cursor: pointer;
  a {
    color: #3ab78f;
    text-decoration: none;
  }
}

.email-popup-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #00000042;
}

.email-popup {
  position: relative;
  max-width: 370px;
  .email-close {
    position: absolute;
    right: 10px;
    top: 10px;
    // padding: 7px;
    // width: 26px;
    // height: 26px;
    width: 15px;
    height: 15px;
    // background-color: #e2e2e2;
    border-radius: 100%;
    z-index: 10;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
}

.error-popup-wrapper {
  animation-name: delayDisplay;
  animation-duration: 5s;
}

.share-platforms {
  margin: 30px 0;
}

.share-link-icon {
  border-radius: 100%;
  z-index: 10;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  color: #292426;
  margin-right: 10px;
}

.share-link-copied {
  position: absolute;
  top: calc(100% + 10px);
  padding: 5px 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  svg {
    margin-left: 10px;
  }
}

.share-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  font-size: 12px;
  &:hover {
    .share-link-label,
    .share-link-icon {
      cursor: pointer;
      opacity: 0.6;
    }
  }
}

@keyframes delayDisplay {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .single-box {
    display: flex;
    flex-direction: column;
    width: 90vw;
    min-width: 300px;
    max-width: 800px;
    height: 90vh;
    min-height: auto;
    background-color: #fff;
    .offer-side {
      padding: 0;
    }
    .content-panel {
      padding: 15px;
      .panel-body {
        padding: 0;
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        p {
          margin-bottom: 0;
        }
      }
      .panel-header {
        span {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    .single-side-actions {
      display: block;
      padding: 20px;
      margin: 0;
      .single-action-get {
        min-width: 100%;
        margin-bottom: 10px;
      }
      .action-save {
        justify-content: center;
        margin: 0;
      }
    }
  }

  .single-close {
    position: fixed;
    right: calc(5vw + 15px);
    top: calc(5vh + 15px);
  }

  .offer-content {
    border: 0;
  }

  .side-image {
    img {
      width: 100%;
      height: auto;
      max-width: 100%;
      object-fit: cover;
    }
  }
  .opl-wrapper {
    overflow: auto;
  }
  .content {
    margin: 0 20px;
  }

  .content-title {
    padding: 0 20px 20px;
    font-size: 16px;
  }

  .email-popup {
    max-width: 90vw;
  }
}

.error-popup {
  margin: 15px 30px 15px;
  text-align: center;
  h4 {
    font-size: 20px;
  }
  span {
    font-size: 14px;
    line-height: 18px;
    display: inline-block;
    padding: 5px 15px;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
  }
}
