.adv-embedd-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #ddd;
}

.dme6 {
  // SWIPER
  .inline-stop-popup {
    overflow: hidden;
    position: relative;
    padding: 15px;
    background-color: #fff;
    font-family: "Poppins";
  }

  .inline-stop-minimize {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background-color: #e2e2e2;
    cursor: pointer;
    svg {
      width: 10px;
    }
    &:hover {
      background-color: #f2f2f2;
    }
  }

  .inline-stop-wrapper {
    &.left {
      .inline-stop-title,
      .inline-stop-footer {
        justify-content: flex-start;
        text-align: left;
      }

      .inline-stop-description {
        text-align: left;
      }

      .inline-stop-swiper {
        justify-content: flex-start;
      }

      .no-navigation {
        .swiper {
          margin-left: 0;
        }
      }
      .preview-f-openfilters {
        align-items: flex-start;
      }

      .preview-f-closedfilters {
        justify-content: flex-start;
      }
    }

    &.right {
      .inline-stop-title,
      .inline-stop-footer {
        justify-content: flex-end;
        text-align: right;
        padding: 0;
      }

      .inline-stop-footer {
        flex-direction: row-reverse;
        justify-content: flex-start;
        svg {
          margin-right: 0;
          margin-left: 10px;
        }
      }

      .no-navigation {
        .swiper {
          margin-right: 0;
        }
      }

      .inline-stop-description {
        text-align: right;
        padding: 0;
      }

      .inline-stop-swiper {
        justify-content: flex-end;
      }

      .preview-f-openfilters {
        align-items: flex-end;
      }

      .preview-f-closedfilters {
        justify-content: flex-end;
      }

      .preview-f-button:last-child {
        margin-right: 0;
      }

      .swiper-wrapper {
        margin-left: auto;
        margin-right: -18px;
      }
    }

    &.center {
      .inline-stop-title,
      .inline-stop-footer {
        justify-content: center;
        text-align: center;
      }

      .inline-stop-description {
        text-align: center;
      }

      .inline-stop-swiper {
        justify-content: center;
      }

      .swiper-wrapper {
        margin: auto;
      }
    }
  }

  .inline-stop-title {
    display: flex;
    align-items: center;
    justify-content: center;
    .inline-stop-title-label {
      font-size: 22px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .inline-stop-title-icon {
    svg {
      max-width: 20px;
      max-height: 20px;
      margin-right: 5px;
    }
  }

  .inline-stop-description {
    text-align: center;
  }

  .hidden {
    display: none;
  }

  .displayed {
    animation: fade 0.5s;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .inline-stop-swiper {
    overflow: hidden;
    .inline-stop-swiper-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      padding: 0 40px;
      &.no-navigation {
        padding: 0;
      }
    }
    .swiper {
      position: initial;
      padding: 20px 0;
      &.swiper-container-nopagination {
        .swiper-wrapper {
          width: fit-content !important;
        }
      }
      .swiper-overflow {
        overflow: hidden;
      }
      .swiper-slide {
        width: 250px;
        height: auto !important;
        margin-bottom: 20px;
      }
      .swiper-item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin-right: 16px;

        input {
          display: none;
        }
        &.display {
          & > label {
            cursor: pointer;
            &:hover {
              .switch-text {
                opacity: 0.6;
              }
            }
          }
        }
        & > label {
          display: flex;
          flex-direction: column;
          padding: 10px;
          height: 100%;
          border-radius: 8px;
          border: 1px solid #d6dae5;
          background-color: #ffffff;
        }
      }

      .swiper-pagination-fraction,
      .swiper-pagination-custom,
      .swiper-horizontal > .swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 0;
      }

      .swiper-pagination-bullet {
        margin: 0 5px;
      }

      .swiper-navigation-dme6 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        .swiper-pagination-dme6 {
          position: relative;
          width: auto;
          margin: 0 20px;
        }
      }

      .swiper-button-next {
        position: relative;
        width: 30px;
        height: 30px;
        right: 0;
        margin: 0;
        &::after {
          content: none;
        }

        &:hover {
          opacity: 0.7;
        }
      }

      .swiper-button-prev {
        position: relative;
        width: 30px;
        height: 30px;
        left: 0;
        margin: 0;

        &::after {
          content: none;
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }
    .item-image {
      min-width: 90px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      margin-bottom: 5px;
      img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        margin: auto;
        margin-top: 0;
        border-radius: 8px;
        object-fit: cover;
        object-position: center;
        overflow: hidden;
        pointer-events: none;
        &.image-xsmall {
          width: 50px;
          height: 50px;
        }
        &.image-small {
          width: 90px;
          height: 90px;
        }
        &.image-medium {
          width: 150px;
          height: 150px;
        }
        &.image-large {
          width: 200px;
          height: 200px;
        }
      }
    }

    .item-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      text-align: left;
      margin: 0 5px;
    }

    .item-get {
      flex-grow: 1;
      display: flex;
      align-items: flex-end;
    }

    .item-get-center {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item-title {
      font-weight: 600;
      font-size: 15px;
    }

    .item-action-get {
      padding: 6px 12px;
      margin-left: 7px;
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }

    .item-info-icon {
      position: relative;
      display: flex;
      align-items: center;
      min-width: 15px;
      margin-left: 7px;
      margin-right: 7px;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
      &::before {
        content: "";
        position: absolute;
        right: -12px;
        top: -10px;
        width: 40px;
        height: 40px;
      }
    }

    .input:checked + .item-layout {
      position: relative;
      border-color: transparent;
      border-color: #3ab78f !important;
      border: 1px solid;
    }
  }

  .item-layout,
  .item-title-text,
  .item-image,
  .item-description {
    cursor: pointer;
  }

  .item-description {
    margin-bottom: 10px;
  }

  .item-email-label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    .item-email-icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      border-radius: 100%;
    }
  }

  .inline-stop-footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 15px;
    font-size: 12px;
    svg {
      min-width: 24px;
      margin-right: 10px;
    }
    &:hover {
      cursor: pointer;
      .inline-stop-tooltip {
        display: block;
      }
    }
  }

  .inline-stop-tooltip {
    display: none;
    position: absolute;
    max-width: calc(100% - 20px);
    bottom: calc(100% + 10px);
    left: 10px;
    padding: 10px;
    background-color: #fef8de;
    color: #333333;
    z-index: 10;
    &::after {
      content: "";
      position: absolute;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid #fef8de;
      bottom: -10px;
      left: calc(50% - 20px);
    }
  }

  .max-2lines {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .max-1lines {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .inline-stop-wrapper {
    &.mobile {
      .inline-stop-swiper {
        .inline-stop-swiper-wrapper {
          padding: 0;
          .swiper {
            padding-bottom: 30px;
            .swiper-pagination {
              display: block;
            }
          }
        }
        .swiper-button-prev,
        .swiper-button-next {
          display: block;
        }

        .swiper-item {
          margin-right: 0px;
        }
      }
    }
  }

  .swiper-wrapper {
    flex-direction: row !important;
  }

  .item-email-input {
    input {
      display: flex !important;
    }
  }
  .item-email-terms {
    margin-bottom: 10px;
  }
}

.dme6-swiper-container {
  &-left {
    display: grid;
    justify-content: flex-start;
  }
  &-right {
    display: grid;
    justify-content: flex-end;
  }
  &-center {
    display: grid;
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .dme6 {
    .inline-stop-swiper {
      .inline-stop-swiper-wrapper {
        padding: 0;
        .inline-stop-popup {
          padding: 0 15px;
        }
        .swiper {
          padding-bottom: 30px;
          .swiper-pagination {
            display: block;
          }
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        display: flex;
      }
    }
  }
}
