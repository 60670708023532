.compact-preview-wrapper {
  &.left {
    justify-content: flex-start;
  }
  &.right {
    justify-content: flex-end;
  }
  &.top {
    align-items: flex-start;
  }
  &.bottom {
    align-items: flex-end;
  }
  &.topLeft {
    justify-content: flex-start;
    align-items: flex-start;
  }
  &.topRight {
    justify-content: flex-end;
    align-items: flex-start;
  }
  &.bottomLeft {
    justify-content: flex-start;
    align-items: flex-end;
  }
  &.bottomRight {
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.compact-preview-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
// SWIPER
.compact-preview-popup {
  padding: 20px 0;
  position: relative;
  max-width: 375px;
  background-color: #fff;
  border: 1px solid;
  border-radius: 12px;
  font-family: "Poppins";
  height: 100%;
  display: flex;
  flex-direction: column;
}

.preview-minimize {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: #e2e2e2;
  cursor: pointer;
  svg {
    width: 10px;
  }
  &:hover {
    background-color: #f2f2f2;
  }
}

.compact-preview-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  .compact-preview-title-label {
    font-size: 22px;
    display: inline-block;
    vertical-align: middle;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.compact-preview-title-icon {
  svg {
    max-width: 20px;
    max-height: 20px;
    margin-right: 5px;
  }
}

.compact-preview-description {
  text-align: center;
  padding: 0 20px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.compact-preview-swiper {
  position: relative;
  padding: 0 40px;
  overflow: hidden;
  .swiper {
    position: initial;
    padding: 10px 5px;
    .swiper-overflow {
      overflow: hidden;
    }
    .swiper-slide {
      height: auto;
    }
    .swiper-item {
      position: relative;
      height: 100%;
      input {
        display: none;
      }
      &.display {
        & > label {
          cursor: pointer;
          &:hover {
            .switch-text {
              opacity: 0.6;
            }
          }
        }
      }
      & > label {
        display: flex;
        flex-direction: column;
        padding: 20px;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #d6dae5;
        background-color: #ffffff;
      }
    }
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: 0;
    }

    .swiper-button-next {
      width: 30px;
      height: 30px;
      right: 10px;
      &::after {
        content: none;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .swiper-pagination-bullet {
      background-color: #a3a8b9;
    }

    .swiper-button-prev {
      width: 30px;
      height: 30px;
      left: 10px;

      &::after {
        content: none;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
  .compact-item-image {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      min-width: 90px;
      width: 90px;
      height: 90px;
      margin: auto;
      margin-top: 0;
      border-radius: 8px;
      object-fit: cover;
      object-position: center;
      overflow: hidden;
      pointer-events: none;
    }
  }

  .compact-item-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-left: 10px;
    min-height: 90px;
  }

  .compact-item-description {
    margin-bottom: 5px;
  }

  .compact-item-get {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .compact-item-get-center {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .compact-item-title {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
  }

  .compact-item-email-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 26px;
    min-height: 28px;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  .compact-item-info-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-width: 26px;
    min-height: 28px;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  .compact-item-share-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-width: 26px;
    min-height: 28px;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  .compact-item-action-get {
    width: 100%;
    padding: 5px 7px;
    margin-right: 5px;
    border-radius: 6px;
    text-align: center;
    line-height: 15px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  .compact-item-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 6px;
  }

  .input:checked + .compact-item-layout {
    position: relative;
    border-color: transparent;
    border-color: #3ab78f !important;
    border: 1px solid;
  }
}

.compact-preview-footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  font-size: 12px;
  svg {
    min-width: 24px;
    margin-right: 10px;
  }

  &:hover {
    cursor: pointer;
    .preview-tooltip {
      display: block;
    }
  }
}

.compact-item-layout,
.compact-item-title-text,
.compact-item-image,
.compact-item-description {
  cursor: pointer;
}

.preview-tooltip {
  display: none;
  position: absolute;
  max-width: calc(100% - 20px);
  bottom: calc(100% + 10px);
  left: 10px;
  padding: 10px;
  background-color: #fef8de;
  color: #333333;
  z-index: 10;
  &::after {
    content: "";
    position: absolute;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fef8de;
    bottom: -10px;
    left: calc(50% - 20px);
  }
}

.max-2lines {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-1lines {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.dashed-button-c13 {
  border: 1px dashed;
  border-color: #656060;
  color: #656060;
  border-radius: 6px;
  width: 100%;
  padding: 6px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 2px;
}
