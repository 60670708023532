.important-margin {
  margin: 0 !important;
}

.dashed-button-template-settings {
  border: 1px dashed;
  border-color: #656060;
  color: #656060;
  border-radius: 6px;
  width: 160px;
  padding: 6px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 1px;
}

.title-padding-top {
  padding-top: 20px;
}
