@font-face {
  font-family: "Screwfix";
  src: url("./fonts/screwfix_regular-webfont_v2.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Screwfix";
  src: url("./fonts/screwfix_bold-webfont_v2.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Screwfix";
  src: url("./fonts/screwfix_heavy-webfont_v2.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
