@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;600;700&display=swap");
@import url("./_curryFont.css");
@import url("./_offers.scss");
@import url("./_single.scss");
@import url("./_extra.scss");
@import url("./newlook-css/_newlookFont.css");
@import url("./screwfix-css/_screwfixFont.css");
@import url("./_extra.scss");
@import url("./_subscription.scss");
@import url("./_GoodHomeFont.css");

html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins;
  &.no-scroll {
    overflow: hidden;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
